<template>
  <v-app :style="{ background: $vuetify.theme.themes.dark.background}">
    <Navbar />
    <v-container fluid>
      <v-row class="row-presentation" id="about_me">
        <v-col cols="6">
          <div class="fade">
            <v-img src="moi.png" contain max-height="500"/>
          </div>
        </v-col>
        <v-col cols="6" class="column-presentation">
          <div class="div-fit-content">
            <h3 class="soft-orange presentation-title">Hello I'm <br class="d-md-none" /> Théophile<br class="d-md-none" /> Mariotte</h3>
          </div>
          <div class="div-fit-content">
            <h1 class="grey--text">Computer Science Student at CESI Bordeaux</h1>
          </div>
          <p class="my-purple">
            Welcome to my portfolio!<br>
            Curently in second year of engineering cycle (210 ECTS).<br>
            Aiming to be Software Engineer after graduating!
          </p>
          <a href="CV.pdf" download="CV.pdf" class="button1">Download my Resume</a>
          <a href="https://www.linkedin.com/in/theophilemariotte/" target="blank" class="button2">Check my Linkedin</a>
          <a href="https://github.com/Teosphalt1" target="blank" class="button1">Check my Github</a>
          <a href="mailto:theophile.mariotte2204@gmail.com" class="button2">Send me an e-mail</a>
        </v-col>
      </v-row>

      <v-row id="pro_exp">
        <v-col cols="12" class="padd">
          <div class="first">
            <h1 class="my-purple  mt-4">Professionnal Experiences</h1>
            <h3 class="grey--text">Here are all the different work experiences I had related to IT since the beginning of my studies</h3>
            <v-row class="mt-5">
              <v-col cols="12">
                <div class="pro_exp">
                  <div class="child bgColor1">
                    <v-icon color="#673AB7" x-large class="ml-3">
                      mdi-web
                    </v-icon>
                    <h3 class="white--text mt-4">Full Stack Web Developper at AWAM</h3>
                    <v-img class="mt-4" src="awam.jpg" contain max-height="500"/>
                    <p class="grey--text ml-3 mt-6">
                      Internship from <b class="my-purple">april to july 2023</b>.<br>
                      Development of a website<br> for the French Ministry of Sports.
                    </p>
                    <v-dialog
                      v-model="dialog1"
                      width="500"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="#673AB7" dark text v-bind="attrs" v-on="on" style="font-weight: bold;">
                          <strong>Know more</strong>
                          <v-icon>mdi-arrow-right</v-icon>
                        </v-btn>
                      </template>
                
                      <v-card>
                        <v-card-title  class="text-h5 my-purple bg-dark">
                          AWAM Full Stack Developper
                        </v-card-title>
                        <v-img src="map_awam.png" contain max-height="500"/>
                        <v-card-text class="bg-dark grey--text">
                          The goal of my mission was to develop an online mapping solution with a dynamic listing of sports
                          clubs for the Agence Nationale du Sport which is one of the French Ministère des Sports firm.<br>
                          This website
                          This internship allowed me to increase my skills in the web sector, but also to discover
                          for the first time, and this in a rather in-depth way, the work environment, as well as the distribution of
                          missions between different sectors of a company.<br>
                          The purpose of this website is to revitalize access to sport in France after a period of Covid and to give a sporting 
                          facade to France in anticipation of the 2024 Olympic Games.<br>
                          Visit website: <a href="https://map.solution-sport-entreprise.fr/" target="_blank">https://map.solution-sport-entreprise.fr/</a>
                        </v-card-text>
                        <v-card-text class="bg-dark grey--text mt-3">
                          <v-row>
                            Skills Acquired:
                            <v-img src="1969px-Laravel.svg.png" contain height="50px" width="50px"/>
                            <v-img src="HTML5_logo_and_wordmark.svg.png" contain height="50px" width="50px"/>
                            <v-img src="CSS3_logo_and_wordmark.svg.png" contain height="50px" width="50px"/>
                            <v-img src="1200px-Javascript-shield.svg.png" contain height="50px" width="50px"/>
                            <v-img src="PHP-logo.svg.png" contain height="50px" width="50px"/>
                            <v-img src="MySQL.svg.png" contain height="50px" width="50px"/> 
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </div>

                  <div class="child bgColor2">
                    <v-icon color="#111111" x-large class="ml-3">
                      mdi-language-python
                    </v-icon>
                    <h3 class="white--text mt-4">Full Stack Python Developper at Thales</h3>
                    <v-img class="mt-4" src="thales-1.svg" contain max-height="500"/>
                    <p class="grey--text ml-3 mt-6">
                      Internship from <b class="text-black">january to april 2023</b>.<br>
                      Development of python scripts to gather data and an applicative server to interface them.
                    </p>
                    <v-dialog
                      v-model="dialog2"
                      width="500"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="#111111" dark text v-bind="attrs" v-on="on" style="font-weight: bold;">
                          <strong>Know more</strong>
                          <v-icon>mdi-arrow-right</v-icon>
                        </v-btn>
                      </template>
                
                      <v-card class="bg-dark">
                        <v-card-title  class="text-h5 my-purple bg-dark">
                          Thales Full Stack Software Developer
                        </v-card-title>
                        <v-img src="cover-r4x3w1200-5c7564136cc87-thales-concoit-environ-25-du-rafale-notamment-le-radar.jpg" contain max-height="500"/>
                        <v-card-text class="bg-dark grey--text mt-3">
                          During this internship i worked in TSIS (Tests et Solutions Intégrées de Soutien), which is a service that work on the development of test solutions to ensure the maintenance in operational condition of the radars of raffales and mirage aircraft via tests benches.<br>
                          My mission consisted firstly in the development of a python package retrieving information about 
                          plugin developed by the TSIS department. Then in a second step, in the development of a Django 
                          application server, allowing me to interface the recovered data, and which can be queried by a third party application. All the tools will be reserved for strictly internal usage by Thales group
                        </v-card-text>
                        <v-card-text class="bg-dark grey--text">
                          <v-row>
                            Skills Acquired:
                            <v-img src="Python-logo-notext.svg.png" contain height="50px" width="50px"/>
                            <v-img src="django.svg" contain height="50px" width="50px"/>
                            <v-img src="api.svg" contain height="50px" width="50px"/>
                            <v-img src="jfrog-artifactory-ico-11562993736qsiwwo3mrn.png" contain height="50px" width="50px"/>
                            <v-img src="Bitbucket-blue-logomark-only.svg.png" contain height="50px" width="50px"/> 
                            <v-img src="AngularJS-Shield.svg" contain height="50px" width="50px"/>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </div>

                  <div class="child bgColor1">
                    <v-icon color="#673AB7" x-large class="ml-3">
                      mdi-xml
                    </v-icon>
                    <v-icon color="#673AB7" x-large class="ml-3">
                      mdi-code-json
                    </v-icon>
                    <h3 class="white--text mt-4">Freelance XML JSON Flows</h3>
                    <v-img class="mt-4" src="2572734.png" contain max-height="500"/>
                    <p class="grey--text ml-3 mt-6">
                      Since<b class="my-purple"> may 2022 </b>I started to work on freelance on the creation of XML and JSON flows for companies.
                    </p>
                    <v-dialog
                      v-model="dialog3"
                      width="500"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="#673AB7" dark text v-bind="attrs" v-on="on" style="font-weight: bold;">
                          <strong>Know more</strong>
                          <v-icon>mdi-arrow-right</v-icon>
                        </v-btn>
                      </template>
                
                      <v-card class="bg-dark">
                        <v-card-title  class="text-h5 my-purple bg-dark">
                          Freelance XML JSON Flows Development
                        </v-card-title>
                        <v-img src="1200px-Logo_du_Groupe_Caisse_des_Dépôts.svg.png" contain max-height="500"></v-img>
                        <v-card-text class="bg-dark grey--text mt-3">
                          Since January 2022, establishments receiving students whose training is financed by the Compte Personnel de Formation (CPF) have been required to send lists of data in XML format to the Caisse des Dépôts et Consignation, which finances these training courses (CDC). I am therefore approaching and assisting companies that do not have an IT department in this process so that they comply with the Act while waiting for the CDC to deploy a solution (date still unknown).
                        </v-card-text>
                        <v-card-text class="bg-dark grey--text mt-3">
                          <v-row>
                            Skills Acquired:
                            <v-img src="xml.svg" contain height="50px" width="50px"/>
                            <v-img src="136525.png" contain height="50px" width="50px"/>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>


          <div class="second" id="projects">
            <v-col cols="12">
              <h1 class="my-purple mt-4">Projects</h1>
              <h3 class="grey--text">Here are some interesting Projects linked to my studies, I've accomplished or that i will accomplish in a nearby future</h3>

              <div class="child_project bgColor1 mt-5">
                <div class="project">
                  <div>
                    <img class="image_project" src="ez_save1.png" onmouseover="this.src='ez_save2.png';" onmouseout="this.src='ez_save1.png';" contain height="100%" width="100%"/>
                  </div>
                  <div>
                    <h1 class="my-purple">C# .NET project Easy Save (OOP MVVM)</h1>
                    <h3 class="grey--text mt-3">This project was carried out in <span class="my-purple">C#</span> with the <span class="my-purple">.NET framework</span> and using an  <span class="my-purple">MVVM Architecture</span> thanks to the <span class="my-purple">WPF framework</span> for the entire front development.</h3>
                    <h4 class="grey--text mt-2">The goal of this project was the development of a software allowing a simultaneous saving thanks to the multi threading. This software can be used in English and French with dynamic language switching. In addition to the interface, a remote application can be deployed to perform remote backups.</h4>
                    <h5 class="grey--text mt-2">This project was carried out in 4 school weeks with 3 other classmates. During this one, I took on the roles of project manager, as well as lead dev.</h5>
                    <div class="skills_project">
                      <h5 class="grey--text  mt-2">Skills acquired:</h5>
                      <v-img src="Logo_C_sharp.svg.png" contain height="50px" width="50px"/>
                      <v-img src="NET_Core_Logo.svg.png" contain height="50px" width="50px"/>
                      <v-img src="wpf.png" contain height="50px" width="50px"/>
                      <v-img src="azure_devops.png" contain height="50px" width="50px"/>
                      <v-img src="jira.png" contain height="50px" width="50px"/>

                    </div>
                    <a style="text-decoration: none;" href="https://github.com/Teosphalt1/EasySave" target="_blank"><h5 class="my-purple">Link github: <v-icon  x-large  class="my-purple">mdi-github</v-icon></h5></a>
                  </div>
                </div>
              </div>

              <div class="child_project bgColor2 mt-5">
                <div class="project">
                  
                  <div>
                    <h1 class="white--text">C++ project M-VBK (OOP)</h1>
                    <h3 class="grey--text mt-3">This project has been realized in <span class="white--text">C++</span> and using <span class="white--text">WinForm</span> for the front, as a formation about <span class="white--text">Object Oriented Programming</span> and the actual use of <span class="white--text">SQL databases.</span></h3>
                    <h4 class="grey--text mt-2">The aim of the project was the realization of a management software of stocks, personnel, customers, as well as the calculation of statistics compared to these data, for a company selling electronic components to professionals and individuals</h4>
                    <h5 class="grey--text mt-2">This project was carried out in 3 school weeks with 3 other classmates. During this one, I took on the roles of project manager, as well as back-end dev, carrying all the back development.</h5>
                    <div class="skills_project">
                      <h5 class="grey--text  mt-2">Skills acquired:</h5>
                      <v-img src="ISO_C++_Logo.svg.png" contain height="50px" width="50px"/>
                      <v-img src="logo-winforms.png" contain height="50px" width="50px"/>
                      <v-img src="microsoft-sql-server-logo.svg" contain height="50px" width="50px"/>
                      <v-img src="25231.png" contain height="50px" width="50px"/>
                      <v-img src="unit_test.png" contain height="50px" width="50px"/>
                    </div>
                    <a style="text-decoration: none;" href="https://github.com/Teosphalt1/Projet_POO_UIUX" target="_blank"><h5 class="white--text">Link github: <v-icon  x-large  class="white--text">mdi-github</v-icon></h5></a>
                  </div>
                  <div>
                    <img class="image_project" src="MVBK2.png" onmouseover="this.src='MVBK1.png';" onmouseout="this.src='MVBK2.png';" contain height="100%" width="100%"/>
                  </div>
                </div>
              </div>

              <div class="child_project bgColor1 mt-5">
                <div class="project">
                  <div>
                    <img class="image_project" src="internsite.png" onmouseover="this.src='map_awam.png';" onmouseout="this.src='internsite.png';" contain height="100%" width="100%"/>
                  </div>
                  <div>
                    <h1 class="my-purple">Web Project Internsite</h1>
                    <h3 class="grey--text mt-3">The Web project has been realized with the basic languages of web as <span class="my-purple">HTML</span>, <span class="my-purple">CSS</span>, <span class="my-purple">JS</span> and <span class="my-purple">PHP</span>, applying a <span class="my-purple">MVC architecture</span> without any framework.</h3>
                    <h4 class="grey--text mt-2">During this project we had to develop entirely a website for a school to connect student and companies in order to facilitate internship searches.</h4>
                    <h5 class="grey--text mt-2">This project was carried out in 3 school weeks with another classmate. During this one, I took on the role of full stack web developer.</h5>
                    <div class="skills_project">
                      <h5 class="grey--text  mt-2">Skills acquired:</h5>
                      <v-img src="HTML5_logo_and_wordmark.svg.png" contain height="50px" width="50px"/>
                      <v-img src="CSS3_logo_and_wordmark.svg.png" contain height="50px" width="50px"/>
                      <v-img src="1200px-Javascript-shield.svg.png" contain height="50px" width="50px"/>
                      <v-img src="PHP-logo.svg.png" contain height="50px" width="50px"/>
                      <v-img src="JMeriseLogoPetit.png" contain height="50px" width="50px"/>
                    </div>
                    <div class="mt-5">
                      <a style="text-decoration: none;" href="https://map.solution-sport-entreprise.fr/" target="_blank"><h5 class="my-purple">As I'm unable to find the source code, here is the link for the website i developed during my first internship: <v-icon  x-large  class="my-purple">mdi-map</v-icon></h5></a>
                    </div>
                    </div>
                </div>
              </div>

              <div class="child_project bgColor2 mt-5">
                <div class="project">
                  <div>
                    <h1 class="white--text">C Arduino project Worldwide Weather Watcher</h1>
                    <h3 class="grey--text mt-3">This project is an <span class="white--text">embedded system</span> project and was realized <span class="white--text">C</span> language to program an <span class="white--text">Arduino Card.</span></h3>
                    <h4 class="grey--text mt-2">The objective of the project was the creation of a system to monitor the weather and environmental conditions at a certain position and embedded on a ship.</h4>
                    <h5 class="grey--text mt-2">This project was carried out in 3 school weeks with 2 other classmates. During this one, I took on the roles of project manager.</h5>
                    <div class="skills_project">
                      <h5 class="grey--text  mt-2">Skills acquired:</h5>
                      <v-img src="C.png" contain height="50px" width="50px"/>
                      <v-img src="Arduino_Logo.svg.png" contain height="50px" width="50px"/>
                      <v-img src="embedded-device-body-wide.png" contain height="50px" width="50px"/>
                      <v-img src="old-vmware-logo.png" contain height="50px" width="50px"/>
                      <v-img src="3dprint.png" contain height="50px" width="50px"/>
                    </div>
                    <a style="text-decoration: none;" href="https://github.com/CESI-SystemeEmbarque-BDX-Grp2/SystemeEmbarque" target="_blank"><h5 class="white--text">Link github: <v-icon  x-large  class="white--text">mdi-github</v-icon></h5></a>
                  </div>
                  <div>
                    <img class="image_project" src="www.jpg" onmouseover="this.src='schema_www.png';" onmouseout="this.src='www.jpg';" contain height="100%" width="80%"/>
                  </div>
                </div>
              </div>

              <div class="child_project bgColor1 mt-5">
                <div class="project">
                  <div>
                    <img class="image_project" src="Flag_of_Canada.png" onmouseover="this.src='laval.avif';" onmouseout="this.src='Flag_of_Canada.png';" contain height="100%" width="100%"/>
                  </div>
                  <div>
                    <h1 class="my-purple">International academic semester at the University of Laval in Quebec City (Bachelor in software engineering)</h1>
                    <h3 class="grey--text mt-3">For the first semester of my second year in engineering school (bac+4), I have the opportunity to go to Canada, in order to take courses in universities, as well as to learn more about other cultures.</h3>
                    <h5 class="grey--text mt-2">Here is the list of courses I will take during this semester<br>
                      - <a href="https://www.ulaval.ca/etudes/cours/gif-1003-programmation-avancee-en-c-pour-lingenierie" style="text-decoration: none;" target="blank"><span class="my-purple">Advanced programmation in C++</span></a><br>
                      - <a href="https://www.ulaval.ca/etudes/cours/gif-1001-ordinateurs-structure-et-applications" style="text-decoration: none;" target="blank"><span class="my-purple">Computers: structure and applications (assembly)</span></a><br>
                      - <a href="https://www.ulaval.ca/etudes/cours/ift-2004-modeles-et-langages-des-bases-de-donnees" style="text-decoration: none;" target="blank"><span class="my-purple">Database models and languages (deepening of the concept)</span></a><br>
                      - <a href="https://www.ulaval.ca/etudes/cours/ift-2003-intelligence-artificielle-i" style="text-decoration: none;" target="blank"><span class="my-purple">Artificial intelligence 1</span></a><br>
                    </h5>
                  </div>
                </div>
              </div>
            </v-col>
          </div>


          <div class="third" id="hobbies">
            <h1 class="my-purple  mt-4">Hobbies</h1>
            <h3 class="grey--text">Here are all the different hobbies that i do during my spare time</h3>
            <v-row class="mt-5">
              <v-col cols="12">
                <div class="pro_exp">
                  <div class="child bgColor1">
                    <v-icon color="#673AB7" x-large class="ml-3">
                      mdi-hockey-sticks
                    </v-icon>
                    <h3 class="white--text mt-4">Sport - Roller Hockey</h3>
                    <v-img class="mt-4" src="roller_hockey.jpg"/>
                    <h4 class="grey--text ml-3 mt-6">
                      I am currently in my <span class="my-purple">8th year of roller hockey</span>. I played in various categories <span class="my-purple">(Junior - R1 - N4 - N3)</span>. The practice of this sport allowed me to have <span class="my-purple">rigor</span> and <span class="my-purple">discipline</span>, as well as to strengthen my <span class="my-purple">communication</span> and my <span class="my-purple">teamwork</span>.
                    </h4>
                  </div>

                  <div class="child bgColor2">
                    <v-icon color="#111111" x-large class="ml-3">
                      mdi-nintendo-game-boy
                    </v-icon>
                    <h3 class="white--text mt-4">Video Games - Play and Develop</h3>
                    <v-img class="d1 mt-4"/>
                    <h4 class="grey--text ml-3 mt-6">
                      My passion for IT and development really comes from video games. Since I started playing, I have experimented with various aspects of the game, from <span class="white--text">casual</span> gaming to <span class="white--text">local CS:GO competitions</span> passing by <span class="white--text">modding</span> and <span class="white--text">creating minecraft servers (≈ 20 active players every day)</span>. Finally I even start making my own little games 2D with <span class="white--text">Unity</span>.
                    </h4>
                  </div>

                  <div class="child bgColor1">
                    <v-icon color="#673AB7" x-large class="ml-3">
                      fa-motorcycle
                    </v-icon>
                    <h3 class="white--text mt-4">Motorcycle and moto sports</h3>
                    <v-img class="mt-4" src="moto.jpg" contain max-height="500"/>
                    <h4 class="grey--text ml-3 mt-6">
                      Since several years i started following famous motor sports competitions as <span class="my-purple">MotoGP</span>, <span class="my-purple">Formula 1</span>, <span class="my-purple">WRC</span>, <span class="my-purple">WEC</span>.
                      One of my dream would be to <span class="my-purple">work for a team</span> later during my career.
                    </h4>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>

        </v-col>
      </v-row>


      
    </v-container>
  </v-app>
  
</template>

<script>
  import Navbar from '@/components/Navbar.vue';

  export default {
    name: 'Home',
    data () {
      return {
        dialog: false,
      }
    },
    components: {
    Navbar
  },
}
</script>

<style scoped>
a.button1{
  display:inline-block;
  padding:0.35em 1.2em;
  border:0.1em solid #673AB7;
  margin:0 0.3em 0.3em 0;
  border-radius:0.12em;
  box-sizing: border-box;
  text-decoration:none;
  font-weight: bold;
  color:#673AB7;
  text-align:center;
  transition: all 0.2s;
}
a.button1:hover{
  color:#111111;
  background-color:#673AB7;
}

a.button2{
  display:inline-block;
  padding:0.35em 1.2em;
  border:0.1em solid #9E9E9E;
  margin:0 0.3em 0.3em 0;
  border-radius:0.12em;
  box-sizing: border-box;
  text-decoration:none;
  font-weight: bold;
  color:#9E9E9E;
  text-align:center;
  transition: all 0.2s;
}
a.button2:hover{
  color:#111111;
  background-color:#9E9E9E;
}
.d1{
  content: url("../../public/cs.jpg");
  animation: fondue 27s ease-in-out infinite both;
}
@keyframes fondue {
  0%{contentcontent: url("../../public/cs.jpg");}
  12.5%{content: url("../../public/minecraft.jpg");}
  25%{content: url("../../public/witcher.png");}
  37.5%{content: url("../../public/rdr2.jpg");}
  50%{content: url("../../public/kcd.webp");}
  62.5%{content: url("../../public/Outer-Wilds.webp");}
  75%{content: url("../../public/road96.jpg");}
  87.5%{content: url("../../public/rl.jpg");}
  100%{content: url("../../public/For-The-King-7.webp");}
}
.skills_project{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.image_project{
  width: 95%;
  height: auto;
}
.project{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: start;
}
template{
  width: 100%;
}
.fade{
  animation: fadeIn 5s;
}
@keyframes fadeIn {
  0% {opacity: 0;}
  100% { opacity: 1;}
}
.pro_exp{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.row-presentation{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.column-presentation{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
.soft-orange{
  color: #d09966
}
.my-purple{
  color: #673AB7;
}

.bg-dark{
  background-color: #282c34;
}
.div-fit-content{
  width: fit-content;
}
.presentation-title{
  width: max-content;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .15em solid #528bff; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  letter-spacing: .15em; /* Adjust as needed */
  animation: 
    typing 2.5s steps(30, end),
    blink-caret .5s step-end infinite;
    border-right: .15em solid transparent;
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #528bff }
}


.topInverse {
  margin-top: -250px;
}
.topTolbar {
  margin-top: 100px;
  text-align: center;
}
.first {
  width: 100%;
  height: 100%;
  height: auto;
  background: linear-gradient(
    to right,
    #282c34,
    #14161a 50%,
    #14161a 50%,
    #111111 70%
  );
  text-align: center;
  padding: 2rem 2rem;
}
.second {
  width: 100%;
  height: auto;
  background: linear-gradient(
    to right,
    #111111,
    #14161a 50%,
    #14161a 50%,
    #282c34 70%
  );
  text-align: center;
  padding: 2rem 2rem;
}
.third{
  width: 100%;
  height: auto;
  background-color: #111111;
  text-align: center;
  padding: 2rem 2rem;
}
.secondchild1 {
  display: inline-block;
  background-color: #111111;
  padding: 2rem 1rem;
  vertical-align: middle;
  text-align: left;
  margin-top: 250px;
}
.child {
  height: 100%;
  width: 20%;
  min-width: 150px;
  display: inline-block;
  padding: 2rem 1rem;
  vertical-align: middle;
  text-align: left;
  margin-right: 8px;
}
.child_project{
  height: 100%;
  width: 80%;
  display: inline-table;
  vertical-align: middle;
  padding: 2rem 1rem;
}
.bgColor1 {
  background-color: #1e1e1e;
}
.bgColor2 {
  background-color: #673AB7;
}

.child1 {
  display: inline-block;
  padding: 2rem 1rem;
  vertical-align: middle;
  margin-right: 5px;
  width: 240px;
}
.child2 {
  display: inline-block;
  width: 245px;
  vertical-align: middle;
}
.mRight {
  margin-right: 8px;
}
.mButton {
  margin-bottom: 8px;
}
.padding {
  padding: 8px 0;
}

.col-12.padd {
  padding: 12px 0 !important;
}
.col-12.childcol {
  padding: 0 !important;
}
h1.number {
  font-size: 50px;
  font-weight: bold;
}

.bg-black
{
  background-color: #111111;
}
.text-black
{
  color: #111111;
}
</style>